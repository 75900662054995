import React, { useEffect } from 'react';
import VpsHeader from './VpsHeader';
import VpsFooter from './VpsFooter';
import About1 from './About1';
import Portfolio from './Portfolio';
import Cta from './Cta';
//import Pricing1 from './Pricing1';
import Faq from './Faq';
import UseCases from './UseCases';

export default function VpsHome(props) {
  const { setLastScreen } = props;
  useEffect(() => {  
    setLastScreen('Home');
  }, [setLastScreen]);

  return (
    <VpsHeader {...props}>
      <About1 {...props} />
      <Portfolio {...props} />
      <Cta {...props} />
      {/*<Pricing1 {...props} />*/}
      <Faq {...props} />
      <UseCases {...props} />
      <VpsFooter {...props} />
    </VpsHeader>
  );
}
