import DT from 'duration-time-conversion';
import { toast } from 'react-toastify';
import Compress from "compress.js"
import axios from "axios";
import FFmpeg from '@ffmpeg/ffmpeg';
import HashMap from 'hashmap';
import { t } from 'react-i18nify';
//import { detect } from 'tinyld';
import { file2sub } from '../libs/readSub';

export const userAgent = window.navigator.userAgent;
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

export const homeUrl = 'https://aitransformer.net/';   
//export const apiBaseUrl = 'http://localhost:9218';   
//export const apiBaseUrl = 'http://192.168.2.88:9218';   
export const apiBaseUrl = 'https://videoplus.studio/api';   
export const chatbotBaseUrl = 'http://192.168.2.88:9220';   
//export const chatbotBaseUrl = 'https://videoplus.studio/chatbot';   

export const SUBSCRIPTION_PLANS = [
    { id: "0", title: "Free" },
    { id: process.env.REACT_APP_PAYPAL_SUBSCRIPTIPON_PLAN1_ID, title: "Lite" },
    { id: process.env.REACT_APP_PAYPAL_SUBSCRIPTIPON_PLAN2_ID, title: "Regular" },
    { id: process.env.REACT_APP_PAYPAL_SUBSCRIPTIPON_PLAN3_ID, title: "Pro" },
];
  
    
export const axiosInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      "Content-type": "application/json"
    },
    withCredentials: true
}); 
  
export function getExt(url) {
    return url.trim().toLowerCase().split('.').pop();
}

export function sleep(ms = 0) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function download(url, name) {
    const elink = document.createElement('a');
    elink.style.display = 'none';
    elink.href = url;
    elink.download = name;
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
}

export function getKeyCode(event) {
    const tag = document.activeElement.tagName.toUpperCase();
    const editable = document.activeElement.getAttribute('contenteditable');
    if (tag !== 'INPUT' && tag !== 'TEXTAREA' && editable !== '' && editable !== 'true') {
        return Number(event.keyCode);
    }
}

export function isPlaying($video) {
    return !!($video.currentTime > 0 && !$video.paused && !$video.ended && $video.readyState > 2);
}

export function downloadFile(url, name) {
    const elink = document.createElement('a');
    elink.style.display = 'none';
    elink.href = url;
    elink.download = name;
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
}

export function notify(text = '', type = 'info') {
    // info success warning error default
    return (
        text.trim() &&
        toast[type](text, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        })
    );
}

export function secondToTime(seconds = 0) {
    return DT.d2t(seconds.toFixed(3));
}

export function timeToSecond(time) {
    return DT.t2d(time);
}

export function replaceFileExtention(filename, extention) {
    const name = filename.substring(0, filename.lastIndexOf('.')) || filename;
    return name + '.' + extention;
}

export async function resizeImage(file) {
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      //maxWidth: 10000, // the max width of the output image, defaults to 1920px
      //maxHeight: 10000, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    const ext = 'jpg';   //img.ext
    const resizedFile = Compress.convertBase64ToFile(img.data, ext);
    return new File([resizedFile], replaceFileExtention(img.alt, ext), {type: ext});
}
    
export async function getBase64String(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}

export function getFileFromBuffer(buffer) {
    const data = buffer.data.data;
    const mime = buffer.contentType || 'image/jpeg';
    const base64Data = btoa(Array.from(new Uint8Array(data)).map(b => String.fromCharCode(b)).join(''));
    const file = Compress.convertBase64ToFile(base64Data, mime);
    return file;
}
  
// Define a method that takes an audio file object as input and returns a mp3 file object as output
export async function convertToMp3(file) {
    // Create a promise that will resolve with the mp3 file object or reject with an error
    return new Promise(async (resolve, reject) => {
        // Create a ffmpeg object with log option
        const { createFFmpeg, fetchFile } = FFmpeg;
        const ffmpeg = createFFmpeg({ log: true });

        // Load the ffmpeg core files
        await ffmpeg.load();

        // Write the file object to the ffmpeg filesystem with the name "input"
        ffmpeg.FS("writeFile", "input", await fetchFile(file));

        // Run the ffmpeg command to convert the input file to mp3 format
        await ffmpeg.run("-i", "input", "output.mp3");

        // Read the output file from the ffmpeg filesystem
        const data = ffmpeg.FS("readFile", "output.mp3");

        // Create a blob from the output data with mp3 mime type
        const blob = new Blob([data.buffer], { type: "audio/mpeg" });

        // Create a file object from the blob with the same name as the input file
        const mp3File = new File([blob], replaceFileExtention(file.name, 'mp3'), {
            type: "audio/mpeg",
            lastModified: Date.now(),
        });

        // Resolve the promise with the mp3 file object
        resolve(mp3File);
    });
}

export const PRESENTER_OPTIONS = [
    { key: '1', name: 'Presenter 1' },
    { key: '2', name: 'Presenter 2' },
];

export const GENDER_OPTIONS = [
    { value:"", text:"All" },
    { value:"male", text:"Male" },
    { value:"female", text:"Female" },
];
  
export const AVATAR_SHAPE_OPTIONS = [
    { value:"original", text:"Original" },
    { value:"original_rembg", text:"Original with background removed" },
    { value:"circle", text:"Circle" },
    { value:"square", text:"Square" },
    { value:"hexagon", text:"Hexagon" },
    { value:"cloud", text:"Cloud" },
];
  
export const AUDIO_OPTIONS = [
    { value:"driving", text:"driving" },
    { value:"background", text:"background" },
];
  
export const OUTPUT_LANGUAGE_OPTIONS = [
    { value:"auto", text:"Auto Detect" },
    { value:"English", text:"English" },
];
  
export const MAX_TEXT_SIZE1 = 300;   //size limit for video subtitle (timeline based) 
export const MAX_TEXT_SIZE2 = 1000;   //size limit for non-video subtitle (slide/frame based)
export const MAX_VIDEO_FILE_SIZE = 1000 * 1024 * 1024;
export const MAX_DOCUMENT_FILE_SIZE = 50 * 1024 * 1024;
export const MAX_AUDIO_FILE_SIZE = 50 * 1024 * 1024;

// To sort the languages by name
export const compareLanguages = (a, b) => {
    if (a.name > b.name) return 1; 
    if (a.name < b.name) return -1; 
    return 0;
}

// To sort the voices by voice_id
export const compareVoices = (a, b) => {
    const key1 = `${9 - a.clonable}${a.voice_id}`
    const key2 = `${9 - b.clonable}${b.voice_id}`
    if (key1 > key2) return 1; 
    if (key1 < key2) return -1; 
    return 0;
}
  
export const isFaceDetected = async(faceDetector, imageFile) => {
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);
    await img.decode();
    const result = faceDetector.detect(img);
    const detections = result.faceLandmarks;   //result.detections;
    //console.log(detections);
    return detections.length > 0;
}

// ref: https://irisreading.com/average-reading-speed-in-various-languages/   863 ± 234 characters/min
const avgCharsPerMinute = new HashMap();
avgCharsPerMinute.set('ar', 612);
avgCharsPerMinute.set('zh', 255);
avgCharsPerMinute.set('nl', 978);
avgCharsPerMinute.set('en', 987);
avgCharsPerMinute.set('fi', 1078);
avgCharsPerMinute.set('fr', 998);
avgCharsPerMinute.set('de', 920);
avgCharsPerMinute.set('it', 950);
avgCharsPerMinute.set('ja', 357);
avgCharsPerMinute.set('pl', 916);
avgCharsPerMinute.set('pt', 913);
avgCharsPerMinute.set('ru', 986);
avgCharsPerMinute.set('sl', 885);
avgCharsPerMinute.set('es', 1025);
avgCharsPerMinute.set('sv', 917);
avgCharsPerMinute.set('tr', 1054);
avgCharsPerMinute.set('hi', 900);
avgCharsPerMinute.set('bn', 900);
avgCharsPerMinute.set('ur', 900);

//import cld from 'cld'; -- not working, Can't resolve 'cld'
export const estimateSpeechDurationForText = (text, charsPerSecond) => {
    let duration = 0;
    /*
    const langInfo = await cld.detect(text);
    for (const lang of langInfo.languages) {
        console.log(`Language is: ${lang.language}`);
        console.log(`Percentage is: ${lang.percentage}`);
        duration += 0.6 * text.length * lang.percentage / (avgCharsPerMinute.get(lang.language) | 900);
    }
    */
    //const lang = detect(text);
    //const charsPerMinute = avgCharsPerMinute.get(lang) | 900;
    //duration += 60 * text.length / charsPerMinute;
    const len = text.split(/\s+/).join('').length;
    const adjustedCPS = charsPerSecond * Math.pow(1.002, len < 180 ? len < 40 ? len - 40 : (180 - len)/2 : 0);
    duration = text.length / adjustedCPS;
    //duration = len / charsPerSecond;
    return duration;  // * 1.03;
}

export const EFFECT_OPTIONS = [
    { value:"cartoonizer_whitebox", text:"Cartoonizer - Whitebox" },
    { value:"cartoonizer_hayao", text:"Cartoonizer - Hayao" },
    { value:"cartoonizer_hosoda", text:"Cartoonizer - Hosoda" },
    { value:"cartoonizer_shinkai", text:"Cartoonizer - Shinkai" },
    { value:"cartoonizer_paprika", text:"Cartoonizer - Paprika" },
    { value:"cartoonizer_celeba_distill", text:"Cartoonizer - Celeba Distill" },
    { value:"cartoonizer_face_paint1", text:"Cartoonizer - Face Paint 1" },
    { value:"cartoonizer_face_paint2", text:"Cartoonizer - Face Paint 2" },
    { value:"filter_overall_enhance", text:"Overall Enhance" },
    //{ value:"filter_enhance_with_face_restore", text:"Enhance with Face Restore" },
    { value:"filter_brighten", text:"Filter - Brighten" },
    { value:"filter_detail_enhance", text:"Filter - Detail Enhance" },
    { value:"filter_sharpen", text:"Filter - Sharpen" },
    { value:"filter_daylight", text:"Filter - Daylight" },
    { value:"filter_warm", text:"Filter - Warm" },
    { value:"filter_cool", text:"Filter - Cool" },
    { value:"filter_sepia", text:"Filter - Sepia" },
    { value:"filter_invert", text:"Filter - Invert" },
    { value:"filter_color_mixup", text:"Filter - Color Mixup" },
    { value:"filter_watercolor", text:"Filter - Watercolor" },
    { value:"filter_pointillism", text:"Filter - Pointillism" },
    { value:"filter_smooth", text:"Filter - Smooth" },
    { value:"filter_blur", text:"Filter - Blur" },
    { value:"filter_emboss", text:"Filter - Emboss" },
    { value:"filter_high_contrast", text:"Filter - High Contrast" },
    { value:"filter_ancient_tv", text:"Filter - Ancient TV" },
    { value:"filter_colormap_spring", text:"Filter - Colormap - Spring" },
    { value:"filter_colormap_summer", text:"Filter - Colormap - Summer" },
    { value:"filter_colormap_autumn", text:"Filter - Colormap - Autumn" },
    { value:"filter_colormap_winter", text:"Filter - Colormap - Winter" },
    { value:"filter_colormap_jet", text:"Filter - Colormap - Jet" },
    { value:"filter_colormap_rainbow", text:"Filter - Colormap - Rainbow" },
    { value:"filter_colormap_ocean", text:"Filter - Colormap - Ocean" },
    { value:"filter_colormap_bone", text:"Filter - Colormap - Bone" },
    { value:"filter_colormap_cool", text:"Filter - Colormap - Cool" },
    { value:"filter_colormap_hot", text:"Filter - Colormap - Hot" },
    { value:"filter_colormap_hsv", text:"Filter - Colormap - HSV" },
    { value:"filter_colormap_pink", text:"Filter - Colormap - Pink" },
    { value:"filter_colormap_deepgreen", text:"Filter - Colormap - Deepgreen" },
    { value:"filter_colormap_parula", text:"Filter - Colormap - Parula" },
    { value:"filter_colormap_magma", text:"Filter - Colormap - Magma" },
    { value:"filter_colormap_inferno", text:"Filter - Colormap - Inferno" },
    { value:"filter_colormap_plasma", text:"Filter - Colormap - Plasma" },
    { value:"filter_colormap_viridis", text:"Filter - Colormap - Viridis" },
    { value:"filter_colormap_cividis", text:"Filter - Colormap - Cividis" },
    { value:"filter_colormap_turbo", text:"Filter - Colormap - Turbo" },
    { value:"filter_colormap_twilight", text:"Filter - Colormap - Twilight" },
    { value:"filter_colormap_twilight_shifted", text:"Filter - Colormap - Twilight Shifted" },
    { value:"filter_duotone_red", text:"Filter - Duotone - Red" },
    { value:"filter_duotone_lime", text:"Filter - Duotone - Lime" },
    { value:"filter_duotone_blue", text:"Filter - Duotone - Blue" },
    { value:"filter_duotone_yellow", text:"Filter - Duotone - Yellow" },
    { value:"filter_duotone_aqua", text:"Filter - Duotone - Aqua" },
    { value:"filter_duotone_magenta", text:"Filter - Duotone - Magenta" },
    { value:"filter_duotone_olive", text:"Filter - Duotone - Olive" },
    { value:"filter_duotone_green", text:"Filter - Duotone - Green" },
    { value:"filter_duotone_purple", text:"Filter - Duotone - Purple" },
    { value:"filter_duotone_teal", text:"Filter - Duotone - Teal" },
    { value:"filter_duotone_gold", text:"Filter - Duotone - Gold" },
    { value:"filter_duotone_coral", text:"Filter - Duotone - Coral" },
    { value:"filter_duotone_orange", text:"Filter - Duotone - Orange" },
    { value:"filter_duotone_pink", text:"Filter - Duotone - Pink" },
    { value:"filter_duotone_lavender", text:"Filter - Duotone - Lavender" },
    { value:"filter_duotone_violet", text:"Filter - Duotone - Violet" },
    { value:"filter_duotone_gold_red", text:"Filter - Duotone - Gold Red" },
    { value:"filter_duotone_coral_blue", text:"Filter - Duotone - Coral Blue" },
    { value:"filter_duotone_custom", text:"Filter - Duotone - Custom Colors" },
  ];
  
  export const HELP_VIDEO_OPTIONS = [
    { key: '1', title: 'How to make dynamic explainer videos from recordings without voice', url: 'https://www.youtube.com/embed/Y4VdSLzUV-4' },
    { key: '2', title: 'How to apply cartoon/filter effects to videos and transcribe videos', url: 'https://www.youtube.com/embed/xj3DbCu5NW8' },
    { key: '3', title: 'How to turn documents into dynamic presentations', url: 'https://www.youtube.com/embed/P8AptX2hT_M' },
    { key: '4', title: 'How to create personalized talking greeting cards', url: 'https://www.youtube.com/embed/3m_CHYOGnLo' },
    { key: '5', title: 'How to combine pictures and words into story-telling videos', url: 'https://www.youtube.com/embed/riiaFHEgewM' },
    { key: '6', title: 'How to dub a movie', url: 'https://www.youtube.com/embed/dYeVWwKsmJk' },
];

export const AUDIO_STEMS_OPTIONS = [
    { value:"all", text:"All" },
    { value:"vocals", text:"Vocals" },
    { value:"instruments", text:"Instruments" },
];
  
export const GENERATION_METHOD_OPTIONS = [
    { value:"prompt", text:"Generate story from a prompt (enter the prompt)" },
    { value:"url", text:"Generate story based on the contents in a web page (paste the URL)" },
    { value:"document", text:"Generate story based on the contents in a document (upload the document, max size: 10 MB)" },
    { value:"custom", text:"Generate voice with supplied text and pictures with custom prompts (enter the custom story)" },
];
  
export const STORY_GENRE_OPTIONS = [
    { value:"", text:"undefined" },
    { value:"picture books", text:"picture books (children ages 0-5)" },
    { value:"early chapter books", text:"early chapter books (children ages 6-8)" },
    { value:"middle grade novels", text:"middle grade novels (children ages 9-12)" },
    { value:"young adult fiction", text:"young adult fiction" },
    { value:"realist literature", text:"realist literature" },
    { value:"literary fiction", text:"literary fiction" },
    { value:"science fiction", text:"science fiction" },
    { value:"fantasy", text:"fantasy" },
    { value:"mystery/thriller", text:"mystery/thriller" },
    { value:"romance", text:"romance" },
    { value:"horror", text:"horror" },
    { value:"historical fiction", text:"historical fiction" },
    { value:"adventure", text:"adventure" },
    { value:"drama", text:"drama" },
    { value:"comedy/satire", text:"comedy/satire" },
    { value:"TL;DR", text:"TL;DR" },
    { value:"first person", text:"first person" },
];
  
export const PICTURE_STYLE_OPTIONS = [
    { value:"base", text:"base" },
    { value:"cb-cartoon", text:"children's book - cartoon style illustrations" },
    { value:"cb-realistic", text:"children's book - realistic style illustrations" },
    //{ value:"cb-wimmelbuch", text:"children's book - wimmelbuch style illustrations" },
    { value:"cb-whimsical", text:"children's book - whimsical style illustrations" },
    { value:"cb-abstract", text:"children's book - abstract style illustrations" },
    //{ value:"cb-moody", text:"children's book - moody style illustrations" },
    { value:"cb-sketch", text:"children's book - line drawing and sketch style illustrations" },
    { value:"cb-vintage", text:"children's book - vintage style illustrations" },
    { value:"cb-manga", text:"children's book - manga style illustrations" },
    { value:"ads-advertising", text:"ads - advertising" },
    /*{ value:"ads-automotive", text:"ads - automotive" },
    { value:"ads-corporate", text:"ads - corporate" },
    { value:"ads-fashion editorial", text:"ads - fashion editorial" },
    { value:"ads-food photography", text:"ads - food photography" },
    //{ value:"ads-gourmet food photography", text:"ads - gourmet food photography" },
    { value:"ads-luxury", text:"ads - luxury" },
    { value:"ads-real estate", text:"ads - real estate" },
    { value:"ads-retail", text:"ads - retail" },*/
    { value:"artstyle-abstract", text:"artstyle - abstract" },
    { value:"artstyle-abstract expressionism", text:"artstyle - abstract expressionism" },
    { value:"artstyle-art deco", text:"artstyle - art deco" },
    { value:"artstyle-art nouveau", text:"artstyle - art nouveau" },
    { value:"artstyle-constructivist", text:"artstyle - constructivist" },
    { value:"artstyle-cubist", text:"artstyle - cubist" },
    { value:"artstyle-expressionist", text:"artstyle - expressionist" },
    { value:"artstyle-graffiti", text:"artstyle - graffiti" },
    { value:"artstyle-hyperrealism", text:"artstyle - hyperrealism" },
    { value:"artstyle-impressionist", text:"artstyle - impressionist" },
    { value:"artstyle-pointillism", text:"artstyle - pointillism" },
    { value:"artstyle-pop art", text:"artstyle - pop art" },
    { value:"artstyle-psychedelic", text:"artstyle - psychedelic" },
    { value:"artstyle-renaissance", text:"artstyle - renaissance" },
    { value:"artstyle-steampunk", text:"artstyle - steampunk" },
    { value:"artstyle-surrealist", text:"artstyle - surrealist" },
    //{ value:"artstyle-typography", text:"artstyle - typography" },
    { value:"artstyle-watercolor", text:"artstyle - watercolor" },
    /*{ value:"futuristic-biomechanical", text:"futuristic - biomechanical" },
    { value:"futuristic-biomechanical cyberpunk", text:"futuristic - biomechanical cyberpunk" },
    { value:"futuristic-cybernetic", text:"futuristic - cybernetic" },
    { value:"futuristic-cybernetic robot", text:"futuristic - cybernetic robot" },
    { value:"futuristic-cyberpunk cityscape", text:"futuristic - cyberpunk cityscape" },
    { value:"futuristic-futuristic", text:"futuristic - futuristic" },
    { value:"futuristic-retro cyberpunk", text:"futuristic - retro cyberpunk" },
    { value:"futuristic-retro futurism", text:"futuristic - retro futurism" },*/
    { value:"futuristic-sci-fi", text:"futuristic - sci-fi" },
    /*{ value:"futuristic-vaporwave", text:"futuristic - vaporwave" },
    { value:"game-bubble bobble", text:"game - bubble bobble" },
    { value:"game-cyberpunk game", text:"game - cyberpunk game" },
    { value:"game-fighting game", text:"game - fighting game" },
    { value:"game-gta", text:"game - gta" },*/
    { value:"game-mario", text:"game - mario" },
    { value:"game-minecraft", text:"game - minecraft" },
    { value:"game-pokemon", text:"game - pokemon" },
    /*{ value:"game-retro arcade", text:"game - retro arcade" },
    { value:"game-retro game", text:"game - retro game" },
    { value:"game-rpg fantasy game", text:"game - rpg fantasy game" },
    { value:"game-strategy game", text:"game - strategy game" },
    { value:"game-streetfighter", text:"game - streetfighter" },
    { value:"game-zelda", text:"game - zelda" },
    //{ value:"misc-architectural", text:"misc - architectural" },
    { value:"misc-disco", text:"misc - disco" },*/
    { value:"misc-dreamscape", text:"misc - dreamscape" },
    { value:"misc-dystopian", text:"misc - dystopian" },
    { value:"misc-fairy tale", text:"misc - fairy tale" },
    { value:"misc-gothic", text:"misc - gothic" },
    //{ value:"misc-grunge", text:"misc - grunge" },
    { value:"misc-horror", text:"misc - horror" },
    { value:"misc-kawaii", text:"misc - kawaii" },
    //{ value:"misc-lovecraftian", text:"misc - lovecraftian" },
    //{ value:"misc-macabre", text:"misc - macabre" },
    { value:"misc-manga", text:"misc - manga" },
    //{ value:"misc-metropolis", text:"misc - metropolis" },
    //{ value:"misc-minimalist", text:"misc - minimalist" },
    { value:"misc-monochrome", text:"misc - monochrome" },
    { value:"misc-nautical", text:"misc - nautical" },
    { value:"misc-space", text:"misc - space" },
    { value:"misc-stained glass", text:"misc - stained glass" },
    //{ value:"misc-techwear fashion", text:"misc - techwear fashion" },
    //{ value:"misc-tribal", text:"misc - tribal" },
    //{ value:"misc-zentangle", text:"misc - zentangle" },
    { value:"papercraft-collage", text:"papercraft - collage" },
    { value:"papercraft-flat papercut", text:"papercraft - flat papercut" },
    /*{ value:"papercraft-kirigami", text:"papercraft - kirigami" },
    { value:"papercraft-paper mache", text:"papercraft - paper mache" },
    { value:"papercraft-paper quilling", text:"papercraft - paper quilling" },
    { value:"papercraft-papercut collage", text:"papercraft - papercut collage" },
    { value:"papercraft-papercut shadow box", text:"papercraft - papercut shadow box" },
    { value:"papercraft-stacked papercut", text:"papercraft - stacked papercut" },
    { value:"papercraft-thick layered papercut", text:"papercraft - thick layered papercut" },*/
    { value:"photo-alien", text:"photo - alien" },
    { value:"photo-film noir", text:"photo - film noir" },
    /*{ value:"photo-glamour", text:"photo - glamour" },
    { value:"photo-hdr", text:"photo - hdr" },
    { value:"photo-iphone photographic", text:"photo - iphone photographic" },
    { value:"photo-long exposure", text:"photo - long exposure" },
    //{ value:"photo-neon noir", text:"photo - neon noir" },*/
    { value:"photo-silhouette", text:"photo - silhouette" },
    /*{ value:"photo-tilt-shift", text:"photo - tilt-shift" },
    { value:"sai-3d-mode", text:"sai - 3d-mode" },
    { value:"sai-analog film", text:"sai - analog film" },
    { value:"sai-anime", text:"sai - anime" },
    { value:"sai-cinematic", text:"sai - cinematic" },
    { value:"sai-comic book", text:"sai - comic book" },
    { value:"sai-craft clay", text:"sai - craft clay" },
    { value:"sai-digital art", text:"sai - digital art" },
    { value:"sai-enhance", text:"sai - enhance" },
    { value:"sai-fantasy art", text:"sai - fantasy art" },
    { value:"sai-isometric", text:"sai - isometric" },
    { value:"sai-line art", text:"sai - line art" },
    { value:"sai-lowpoly", text:"sai - lowpoly" },
    { value:"sai-neonpunk", text:"sai - neonpunk" },
    { value:"sai-origami", text:"sai - origami" },
    { value:"sai-photographic", text:"sai - photographic" },
    { value:"sai-pixel art", text:"sai - pixel art" },
    { value:"sai-texture", text:"sai - texture" },
    { value:"artist: Amanda Clark", text:"artist: Amanda Clark" },
    { value:"artist: Anna Bocek", text:"artist: Anna Bocek" },
    { value:"artist: Anna Dittmann", text:"artist: Anna Dittmann" },
    { value:"artist: Aron Demetz", text:"artist: Aron Demetz" },
    { value:"artist: Carl Barks", text:"artist: Carl Barks" },
    { value:"artist: Chris Dyer", text:"artist: Chris Dyer" },
    { value:"artist: Christopher Bucklow", text:"artist: Christopher Bucklow" },
    { value:"artist: Cliff Chiang", text:"artist: Cliff Chiang" },
    { value:"artist: Daniel Arsham", text:"artist: Daniel Arsham" },
    { value:"artist: Daniel Clowes", text:"artist: Daniel Clowes" },
    { value:"artist: David Aja", text:"artist: David Aja" },
    { value:"artist: Etam Cru", text:"artist: Etam Cru" },
    { value:"artist: Eyvind Earle", text:"artist: Eyvind Earle" },
    { value:"artist: Fritz Eichenberg", text:"artist: Fritz Eichenberg" },
    { value:"artist: Giorgio de Chirico", text:"artist: Giorgio de Chirico" },
    { value:"artist: Harold Edgerton", text:"artist: Harold Edgerton" },
    { value:"artist: Hsiao Ron Cheng", text:"artist: Hsiao Ron Cheng" },
    { value:"artist: Jessica Durrant", text:"artist: Jessica Durrant" },
    { value:"artist: Lois van Baarle", text:"artist: Lois van Baarle" },
    { value:"artist: Lori Earley", text:"artist: Lori Earley" },
    { value:"artist: Peter Elson", text:"artist: Peter Elson" },
    { value:"artist: PJ Crook", text:"artist: PJ Crook" },
    { value:"artist: Rachel Bingaman", text:"artist: Rachel Bingaman" },
    { value:"artist: Robert Crumb", text:"artist: Robert Crumb" },
    { value:"artist: Ron English", text:"artist: Ron English" },
    { value:"artist: Sergio Aragonés", text:"artist: Sergio Aragonés" },
    { value:"artist: Terry Dodson", text:"artist: Terry Dodson" },
    { value:"artist: Tim Burton", text:"artist: Tim Burton" },
    { value:"artist: Tom Dixon", text:"artist: Tom Dixon" },
    { value:"artist: Tristan Eaton", text:"artist: Tristan Eaton" },
    { value:"artist: Will Eisner", text:"artist: Will Eisner" },
    { value:"artist: Wim Delvoye", text:"artist: Wim Delvoye" },
    { value:"movie: 300", text:"movie: '300' directed by Zack Snyder" },
    { value:"movie: A.I.", text:"movie: 'A.I.' directed by Steven Spielberg" },
    { value:"movie: Akira", text:"movie: 'Akira' directed by Katsuhiro Otomo" },
    { value:"movie: Avatar", text:"movie: 'Avatar' directed by James Cameron" },
    { value:"movie: Blue Velvet", text:"movie: 'Blue Velvet' directed by David Lynch" },
    { value:"movie: Brazil", text:"movie: 'Brazil' directed by Terry Gilliam" },
    { value:"movie: The Cell", text:"movie: 'The Cell' directed by Tarsem Singh" },
    { value:"movie: Dark City", text:"movie: 'Dark City' directed by Alex Proyas" },
    { value:"movie: Django Unchained", text:"movie: 'Django Unchained' directed by Quentin Tarantino" },
    { value:"movie: Dune (2021)", text:"movie: 'Dune (2021)' directed by Denis Villeneuve" },
    { value:"movie: Ex Machina", text:"movie: 'Ex Machina' directed by Alex Garland" },
    { value:"movie: The Fall", text:"movie: 'The Fall' directed by Tarsem Singh" },
    { value:"movie: The Grand Budapest Hotel", text:"movie: 'The Grand Budapest Hotel' directed by Wes Anderson" },
    { value:"movie: Hero", text:"movie: 'Hero' directed by Zhang Yimou" },
    { value:"movie: Life of Pi", text:"movie: 'Life of Pi' directed by Ang Lee" },
    { value:"movie: The Lord of the Rings", text:"movie: 'The Lord of the Rings' directed by Peter Jackson" },
    { value:"movie: Mad Max - Fury Road", text:"movie: 'Mad Max - Fury Road' directed by George Miller" },
    { value:"movie: The Matrix", text:"movie: 'The Matrix' directed by the Wachowskis" },
    { value:"movie: Melancholia", text:"movie: 'Melancholia' directed by Lars von Trier" },
    { value:"movie: Parasite", text:"movie: 'Parasite' directed by Bong Joon Ho" },
    { value:"movie: Sin City", text:"movie: 'Sin City' directed by Robert Rodriguez and Frank Miller" },
    { value:"movie: Spider-Man - Into the Spider-Verse", text:"movie: 'Spider-Man - Into the Spider-Verse'" },
    { value:"movie: Spirited Away", text:"movie: 'Spirited Away' directed by Hayao Miyazaki" },
    { value:"movie: Tron Legacy", text:"movie: 'Tron Legacy' directed by Joseph Kosinski" },
    { value:"movie: V for Vendetta", text:"movie: 'V for Vendetta' directed by James McTeigue" },*/
    { value:"custom", text:"*** Custom Style ***" },
];
  
export const PICTURE_RESOLUTION_OPTIONS = [
    { value:"1", text:"1920 x 1080 (YouTube, LinkedIn)" },
    { value:"2", text:"1080 x 1920 (YouTube Shorts, Instagram, TikTok, Snapchat, LinkedIn)" },
    { value:"3", text:"1280 x 720 (YouTube, Facebook, X, LinkedIn)" },
    { value:"4", text:"1080 x 1080 (YouTube, Facebook, Instagram, LinkedIn)" },
    { value:"5", text:"720 x 1280 (Facebook, X, LinkedIn)" },
    { value:"6", text:"720 x 720 (X, LinkedIn)" },
    { value:"7", text:"1024 x 768" },
    { value:"8", text:"1536 x 1152" },
];
  
export const STORY_OUTPUT_OPTIONS = [
    { value:"video", text:"Video and story text" },
    { value:"gif", text:"GIF image and subtitle json (suitable as input of subtitle-to-speech task)" },
];
  
export const isUrlValid = (string) => {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
}
  
export const openVideo = (file, props) => {
    const { videoPlayer, notify, setSubtitle, newSub, setCarouselData, setLoading } = props;
    if (file) {
        setLoading(true);
        if (file.size < 100 || file.size > MAX_VIDEO_FILE_SIZE) {  //a too small file is probably invalid
            setLoading(false);
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: file.name }),
                level: 'error',
            });
        } else {
            const ext = getExt(file.name);
            const canPlayType = videoPlayer.current.canPlayType(file.type);
            if (canPlayType === 'maybe' || canPlayType === 'probably') {
                const url = URL.createObjectURL(new Blob([file]));
                videoPlayer.current.currentTime = 0;
                setSubtitle([
                    newSub({
                        start: '00:00:00.000',
                        end: '00:00:01.000',
                        text: t('SUB_TEXT'),
                        presenter: '1'
                    }),
                ]);
                videoPlayer.current.src = url;
                setCarouselData(null);
                //setIsDefaultVideo(false);
                setLoading(false);
                //document.getElementById("openVideo").style.display = "none";
            } else {
                setLoading(false);
                notify({
                    message: `${t('VIDEO_EXT_ERR')}: ${file.type || ext}`,
                    level: 'error',
                });
            }
        }
    }
}

export const openDocument = (file, props) => {
    const { notify, setLoading, setCurrentIndex, setSubtitle, newSub, setCarouselData, sid, language } = props;
    if (file) {
        setLoading(true);
        if (file.size > MAX_DOCUMENT_FILE_SIZE) {  
            setLoading(false);
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: file.name }),
                level: 'error',
            });
        } else {
            let formData = new FormData();
            formData.append("sid", sid);
            formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
            formData.append("type", file['type']);
            const selectedFiles = [];
            selectedFiles.push(file);
            selectedFiles.forEach(f => {
                formData.append("file", f);
            });
    
            axiosInstance.post("/runPresentationMaker", formData, {  
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                //notify({
                //    message: response.data.message,
                //    level: 'success',
                //});
                const resultsFolder = response.data.userFolder4PresentationMaker;

                fetch(`${apiBaseUrl}/files/${resultsFolder}/subtitles.json`)
                .then(response => response.json())
                .then(data => {
                    setCarouselData({type: file['type'], dir: resultsFolder});
                    const subs = [];
                    let i = 0;
                    data.forEach(v => {
                        subs.push(newSub({
                            start: DT.d2t(i),
                            end: DT.d2t(i+1),
                            text: v.text,
                            fname: v.fname,
                            presenter: '1'
                        }));
                        ++i;
                    });
                    setSubtitle(subs);
                    setCurrentIndex(0);
                    setLoading(false);
                })
            })
            .catch((reason) => {
                setLoading(false);
                if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                    notify({
                        message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                        level: 'error',
                    });
                } else {
                    notify({
                        message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                        level: 'error',
                    });
                }
            })
        }
    }
}

export const openSubtitle = (file, props) => {
    const { setLoading, copySubs, notify, setSubtitle, carouselData } = props;
    if (file) {
        setLoading(true);
        const ext = getExt(file.name);
        if (['ass', 'vtt', 'srt', 'json'].includes(ext)) {
            file2sub(file)
            .then((res) => {
                if (!carouselData) {
                    //clearSubs();
                    setSubtitle(res);
                } else {
                    const subs = copySubs();
                    subs.forEach((sub, index) => {
                        if (res.length > index) {
                            sub.text = res[index].text;
                            sub.presenter = res[index].presenter;
                        }
                    })
                    setSubtitle(subs);
                }
            })
            .catch((err) => {
                notify({
                    message: err.message,
                    level: 'error',
                });
            });
            setLoading(false);
        } else {
            setLoading(false);
            notify({
                message: t('SUB_EXT_ERR'),
                level: 'error',
            });
        }
    }
}

export const saveImagesAsGif = (props) => {
    const { notify, setLoading, carouselData, subtitle, sid, language } = props;
    if (!carouselData || !carouselData.dir) {  
        notify({
            message: t('ERROR_MESSAGE_MISSING_IMAGE_FILE'),
            level: 'error',
        });
    } else {
        setLoading(true);
        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("type", 'save_gif');
        formData.append("imagesFolder", carouselData.dir);
        const text = JSON.stringify(subtitle);
        const subtitleFile = new File([text], 'subtitle.json');
        formData.append("file", subtitleFile);

        axiosInstance.post("/runPresentationMaker", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            setLoading(false);
            const resultsFolder = response.data.userFolder4PresentationMaker;
            const resultFile = response.data.resultFile;
            window.location.assign(`${apiBaseUrl}/files/${resultsFolder}/${resultFile}`);
        })
        .catch((reason) => {
            setLoading(false);
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
        })
    }
}

  