import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
//import Navbar from './Navbar';
import { DashboardLayout } from "../dashboard/Layout.tsx";
import { chatbotBaseUrl } from '../utils';
import Chatbot, {FloatingActionButtonTrigger, ModalView} from "mongodb-chatbot-ui";

export default function VpsHeader(props) {
    const [theme] = useLocalStorage('theme', 'light');
    const suggestedPrompts = [
        "What can I do with the app?",
        "How to apply cartoon and filter effects to videos?",
        "How to combine pictures and words into story-telling videos?",
    ];
    
    return (
        <div>
            <DashboardLayout {...props} />
        </div>
    );
}
